import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import useReactRouter from "use-react-router";


import Consts from "../../../consts"
import UploadPhoto from "../../../helpers/UploadPhoto";

import Bgprograme from "../../../image/programe/bgPrograme.png"
import P3_1 from "../../../image/programe/program3/pro3-1.jpg"
import P3_2 from "../../../image/programe/program3/pro3-2.jpg"
import P3_3 from "../../../image/programe/program3/pro3-3.jpg"
import P3_4 from "../../../image/programe/program3/pro3-4.jpg"
import P3_5 from "../../../image/programe/program3/pro3-5.JPG"
import P3_6 from "../../../image/programe/program3/pro3-6.jpg"
import P3_7 from "../../../image/programe/program3/pro3-7.JPG"
import P3_8 from "../../../image/programe/program3/pro3-8.JPG"
import NewsCover from "../../../image/blog/NewsCover.png"



import { FaAngleRight } from "react-icons/fa";
import { Modal, Container, Row, Col, Button, Form } from 'react-bootstrap'
import { FaTimes } from "react-icons/fa";

import Footer from "../../../components/footer"

import './Index.css'


function AboutPro2020() {
    const { history } = useReactRouter();


    return (
        <div style={{ zIndex: 0 }}>
            <div style={{ height: 150 }} className='hideHeight'></div>




            <Container fluid className="m-0 p-0 mb-5 bgImage-about-pro">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 banner-about-pro">
                        <img className='imgBg-about-pro' src={NewsCover} />

                        <div className="centered-about-pro">
                            <p>About us</p>
                        </div>

                        <div className="container-list">
                            <div className='list-items ' onClick={() => history.push(Consts.PAGE_ABOUT)}> Story</div>
                              <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2024)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2019)}> ZWL 2024</span></div>
                                                        <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2023)}> <span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2023)}>ZWL 2023</span></div>
                            <div className='list-items ' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2022)}> <span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2022)}>ZWL 2022</span></div>
                            <div className='list-items ' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2021)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2021)}> ZWL 2021</span></div>
                            <div className='list-items action-programe' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2020)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2020)} > ZWL 2020</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2019)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2019)}> ZWL 2019</span></div>
                        </div>
                    </Col>
                </Row>
            </Container>



            <Container className="mTop">
                <Row className="proFix1">
             
                    <Col xs={12} sm={12} md={12}>
                        <center>
                            <p className="proTitle" style={{ textAlign: 'center' }}>“The year that we begin to grow”                          </p>
                            <p className="proTitle">Green, clean, beautiful Vientiane (2/2020)                            </p>
                            <img className="proImage" src={P3_1} />
                        </center>
                    </Col>

                    {/* <Col xs={12} sm={6} md={6}>
                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;Before the event, ZWL held the first drawing contest for young people to participate in during lockdown under the theme of "Youth action in Climate Change”, as the result, all of the drawings that were sent to us are used to be displayed as an exhibition! There were 149 youths who participated in this event via Zoom along with 40 of our young volunteers!

                        </p>
                    </Col> */}

                </Row>
                {/* <hr /> */}
                <Row className='mt-4'>
                    <Col xs={12} sm={12} md={12}>
                        <center>
                            <p className="proTitle proTitleFix">Save green, clean southern laos (6/2020) </p>

                            <img className="proImage" src={P3_2} />
                        </center>
                    </Col>

                    {/* <Col xs={12} sm={6} md={6}>

                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;35 young leaders have participated in the Sustainability Leaders program as the theme of Youth4 SDGs 2021. The objective of this program is to build youth capacity by conducting soft skills training, system thinking, sustainability mindset. During the last session of the program, 35 Youth leaders also joined Sustainability Leaders Camp for 2 days.
                        </p>

                    </Col> */}
                </Row>
                {/* <hr /> */}

                <Row className='mt-4'>
                    <Col xs={12} sm={12} md={12}>

                        <center>
                            <p className="proTitle proTitleFix">Home composting workshop (8/2020) </p>
                            <img className="proImage" src={P3_3} />
                        </center>
                    </Col>

                    {/* <Col xs={12} sm={6} md={6}>

                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;On 25-26 December 2021, Zero Waste Laos had a camping event in Nahoi Villgae, Sangthong District, Vientiane capital. Six groups of energetic youths gathered and created their memorable experiences together including fire camping, sharing activities, meditation and more!

                        </p>

                    </Col> */}


                </Row>

                <Row className='mt-4'>
                    <Col xs={12} sm={12} md={12}>

                        <center>
                            <p className="proTitle proTitleFix">Join Activity at NT2 (9/2020) </p>
                            <img className="proImage" src={P3_4} />
                        </center>
                    </Col>

                    {/* <Col xs={12} sm={6} md={6}>

                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;On 25-26 December 2021, Zero Waste Laos had a camping event in Nahoi Villgae, Sangthong District, Vientiane capital. Six groups of energetic youths gathered and created their memorable experiences together including fire camping, sharing activities, meditation and more!

                        </p>

                    </Col> */}
                </Row>

                <Row className='mt-4'>
                    <Col xs={12} sm={12} md={12}>

                        <center>
                            <p className="proTitle proTitleFix">Zero Waste Camping at NT2 (10/2020)</p>
                            <img className="proImage" src={P3_5} />
                        </center>
                    </Col>

                    {/* <Col xs={12} sm={6} md={6}>

                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;On 25-26 December 2021, Zero Waste Laos had a camping event in Nahoi Villgae, Sangthong District, Vientiane capital. Six groups of energetic youths gathered and created their memorable experiences together including fire camping, sharing activities, meditation and more!

                        </p>

                    </Col> */}
                </Row>

                <Row className='mt-4'>
                    <Col xs={12} sm={12} md={12}>

                        <center>
                            <p className="proTitle proTitleFix">Sustour 2020 at Crown Plaza (9/2020)</p>
                            <img className="proImage" src={P3_6} />
                        </center>
                    </Col>

                    {/* <Col xs={12} sm={6} md={6}>

                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;On 25-26 December 2021, Zero Waste Laos had a camping event in Nahoi Villgae, Sangthong District, Vientiane capital. Six groups of energetic youths gathered and created their memorable experiences together including fire camping, sharing activities, meditation and more!

                        </p>

                    </Col> */}
                </Row>

                <Row className='mt-4'>
                    <Col xs={12} sm={12} md={12}>

                        <center>
                            <p className="proTitle proTitleFix">Youth 4 SDGs (12/2020)</p>
                            <img className="proImage" src={P3_7} />
                        </center>
                    </Col>

                    {/* <Col xs={12} sm={6} md={6}>

                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;On 25-26 December 2021, Zero Waste Laos had a camping event in Nahoi Villgae, Sangthong District, Vientiane capital. Six groups of energetic youths gathered and created their memorable experiences together including fire camping, sharing activities, meditation and more!

                        </p>

                    </Col> */}
                </Row>
                <Row className='mt-4'>
                    <Col xs={12} sm={12} md={12}>

                        <center>
                            <p className="proTitle proTitleFix">Youth 4 SDGs Southern Laos (12/2020)</p>
                            <img className="proImage" src={P3_8} />
                        </center>
                    </Col>

                    {/* <Col xs={12} sm={6} md={6}>

                        <p className="proDetail">&nbsp;&nbsp;&nbsp;&nbsp;On 25-26 December 2021, Zero Waste Laos had a camping event in Nahoi Villgae, Sangthong District, Vientiane capital. Six groups of energetic youths gathered and created their memorable experiences together including fire camping, sharing activities, meditation and more!

                        </p>

                    </Col> */}
                </Row>

            </Container>





            <Footer />
        </div>
    )
}
export default AboutPro2020