
const MAIN_BODY = {
  paddingTop: 60,
  fontSize: '14px'
};

const MAIN_CARD = {
  backgroundColor: "#ffffff",
  minHeight: '100vh',
  marginLeft: 15,
  marginRight: 15,
  padding: 30
}


module.exports = {
  // ======> url photo
  URL_FOR_SHOW_PHOTO: "https://bucket-zerowest-files.s3.ap-southeast-1.amazonaws.com/images/",
  //key ສຳເລັດປັກມຸດ
  KEY_API_GOOGLEMAP: "",
  // ======>
  FRIST_COLOR: "#00A991",
  SECONDARY_COLOR: "#3F4952",
  THIRD_COLOR: "#CBD5E1",
  MAIN_BODY,
  MAIN_CARD,
  PAGE_HOME: "/home",
  PAGE_HOME2: "/home-website",
  PAGE_BLOG: "/blog",
  PAGE_BLOG_DETAIL: "/blog-detail",
  PAGE_CONTACT: "/contact",
  PAGE_VOLUNTEER: "/volunteer",
  PAGE_ABOUT: "/about",
  PAGE_DONATE: "/donate",
  PAGE_PROGRAME1: "/program1",
  PAGE_PROGRAME2: "/program2",
  PAGE_PROGRAME3: "/program3",
  PAGE_PROGRAME4: "/program4",
  PAGE_ABOUT_PRO2022: "/about-pro2022",
  PAGE_ABOUT_PRO2021: "/about-pro2021",
  PAGE_ABOUT_PRO2020: "/about-pro2020",
  PAGE_ABOUT_PRO2019: "/about-pro2019",
  PAGE_ABOUT_PRO2023: "/about-pro2023",
  PAGE_ABOUT_PRO2024: "/about-pro2024",
  PAGE_DONATE_FORM: "/donate-form",
  USER_ACCOUNT: '/user-account',
  USER_PROFILE:"/user-profile",
  USER_APPROVED: '/user-approved',
  USER_PLANT_DONATE:"/user-plant-donate",
  USER_KEY: "ZEROWASTE ",
  PAGE_LOGIN: "/admin",
  PAGE_DESHBOARD: "/admin-dashboard",

  // staff
  PAGE_STAFF: "/admin-staff",
  PAGE_STAFF_ADD: "/admin-staff-add",
  PAGE_STAFF_DETAIL: "/admin-staff-detail",
  PAGE_STAFF_EDIT: "/admin-staff-edit",

  // Volunteer

  PAGE_VOLUNTEER_LIST: "/admin-volunteer",
  PAGE_ADD_VOLUNTEER: "/admin-add-volunteer",
  PAGE_EDIT_VOLUNTEER: "/admin-edit-volunteer",
  PAGE_VOLUNTEER_DETAIL: "/admin-volunteer-detail",

  // Climate
  PAGE_CLIMATE_LIST: "/admin-climate",
  PAGE_APPORVERD_LIST: "/admin-climate-apporverd",
  PAGE_CLIMATE_DETAIL: "/admin-climate-detail",
  PAGE_REJECT_LIST: "/admin-climate-reject",
  PAGE_CLIMATE_ADD: "/admin-climate-add",
  PAGE_CLIMATE_EDIT: "/admin-climate-edit",
  PAGE_PEDDING_LIST:"/admin-climate-pending",


  //settings
  PAGE_SETTING_MENU: "/admin-settings",
  PAGE_CERTIFICATE_CATEGORY_LIST: "/admin-certificate-category",
  PAGE_CERTIFICATE_CATEGORY_ADD: "/admin-certificate-category-add",
  PAGE_CERTIFICATE_CATEGORY_UPDATE: "/admin-certificate-category-update",
  PAGE_POST_CATEGORY_LIST: "/admin-post-category-list",
  PAGE_POST_CATEGORY_ADD: "/admin-post-category-add",
  PAGE_POST_CATEGORY_UPDATE: "/admin-post-category-update",

  // Post
  PAGE_POST_LIST: "/admin-post",
  PAGE_ADD_POST: "/admin-add-post",
  PAGE_EDIT_POST: "/admin-edit-post",
  PAGE_POST_DETAIL: "/admin-post-detail",

  //CLIMATE_ACTION
  PAGE_CLIMATE_ACTION_WAITING: "/admin-climate-action-waiting-list",
  PAGE_CLIMATE_ACTION_APPROVED: "/admin-climate-action-approved",
  PAGE_CLIMATE_ACTION_REJECT: "/admin-climate-action-rejected",
  PAGE_CLIMATE_ACTION_ADD: "/admin-climate-action-add",
  PAGE_CLIMATE_ACTION_DETAIL: "/admin-climate-action-detail",
  PAGE_CLIMATE_ACTION_UPDATE: "/admin-climate-action-update",
  //PAGE_CERTIFICATE
  PAGE_CERTIFICATE_LIST: "/admin-certificate",
  PAGE_CERTIFICATE_ADD: "/admin-certificate-add",
  PAGE_CERTIFICATE_UPADTE:"/admin-certificate-update",
  PAGE_CERTIFICATE_DETAIL:"/admin-certificate-detail",
  //PAGE_DONATE
  PAGE_DONATE_LIST: "/admin-donate",
  PAGE_DONATE_DETAIL:"/admin-donate-detail",
};
