import React, { useState } from 'react'
import useReactRouter from "use-react-router";


import Consts from "../../../consts"


import { Container, Row, Col, Button, ListGroup } from 'react-bootstrap'
import { FaCircle } from "react-icons/fa";

import M2 from "../../../image/mission/m2.png"
import M1 from "../../../image/mission/m1.png"
import M4 from "../../../image/mission/m4.png"
import M5 from "../../../image/mission/m5.png"
import M3 from "../../../image/mission/m3.png"
import Bgprograme from "../../../image/programe/bgPrograme.png"
import NewsCover from "../../../image/blog/NewsCover.png"


import AboutImg1 from "../../../image/about/aboutImg1.png"
import AboutImg2 from "../../../image/about/aboutImg2.png"
import AboutImg3 from "../../../image/about/aboutImg3.png"
import AboutImg4 from "../../../image/about/aboutImg4.png"
import AboutImg5 from "../../../image/about/aboutImg5.png"
import AboutImg6 from "../../../image/about/aboutImg6.png"
import AboutImg7 from "../../../image/about/aboutImg7.png"

import Climatechange from "../../../image/about/Climatechange.JPG"
import IT from "../../../image/about/IT.png"
import management from "../../../image/about/management.png"
import SDGS from "../../../image/about/SDGS.png"
import Youth from "../../../image/about/Youth.JPG"
import Solidwastemanagement from "../../../image/about/Solidwastemanagement.JPG"



import Footer from "../../../components/footer"

import './Index.css'


function About() {
    const { history } = useReactRouter();

    const pro1 = () => {
        console.log("ddd")
    }

    return (
        <div style={{ zIndex: 0 }}>
            <div className='hideHeight'></div>
            <Container fluid className="m-0 p-0 mb-5 bgImage-about-pro">
                <Row className="m-0 p-0">
                    <Col md='12' xs={12} className="m-0 p-0 banner-about-pro">
                        <img className='imgBg-about-pro' src={NewsCover} />

                        <div className="centered-about-pro">
                            <p className="proMainTitle">About Us</p>
                        </div>
                        <div className="container-list">
                            <div className='list-items action-programe' onClick={() => history.push(Consts.PAGE_ABOUT)}> <span onClick={() => history.push(Consts.PAGE_ABOUT)} >Story</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2024)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2019)}> ZWL 2024</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2023)}> <span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2023)}>ZWL 2023</span></div>
                            <div className='list-items ' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2022)}> <span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2022)}>ZWL 2022</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2021)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2021)}> ZWL 2021</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2020)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2020)} > ZWL 2020</span></div>
                            <div className='list-items' onClick={() => history.push(Consts.PAGE_ABOUT_PRO2019)}><span onClick={() => history.push(Consts.PAGE_ABOUT_PRO2019)}> ZWL 2019</span></div>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container style={{ zIndex: 0 }} className='mTop'>
                <Row className='aboutTop mt-4'>
                    <Col xs={12} md={12}>
                        <div className='title' >
                            <h2 id="bigTitle" ><span className="title-Active">OUR</span> Story</h2>
                            <p className="titleDetail" style={{ textAlign: "justify", textJustify: "inter-word" }}>&nbsp;&nbsp;&nbsp;&nbsp; ZWL was initially established in 2019 as a volunteering group for promoting sustainable waste management through awareness campaigns via social media platforms in Vientiane, Lao PDR. Our first event was carried out in partnership with UNV on Mekong River Volunteering Action with more than 300 participants. ZWL organized the event with support from many development partners. We are recognized as a youth-led organization on environmental issues. Therefore, various sectors reach out to us for collaboration, mainly development partners, INGOs, NGOs, government, and private sectors, which are more than 20 partners who have cooperated with us.  ZWL assists many parts of the country to advance their environment and sustainable development goals through the provision of capacity-building, knowledge sharing, policy advice, and research in four thematic areas: Waste Management, Climate Change, Sustainability, Youth Development and gender.  Our competitive advantages include, but not limited to:</p>
                            <div className='storyUl' style={{
                                listStyleType: "disc",
                                marginLeft: "20px",
                                textAlign: "left",
                                fontWeight: "400",
                                fontSize: "16px",
                                color: "#000000"
                            }}>
                                <p style={{ color: "#000000" }}>- Nearly three years of experience in operating landmark environment initiatives in Lao PDR.</p>
                                <p style={{ color: "#000000" }}>- One-stop Organization for various Environmental Issues.</p>
                                <p style={{ color: "#000000" }}>- Lao PDR presence - supporting various schools and communities on waste management in Laos, and adaptation to climate change, leading green and sustainability initiatives.</p>
                                <p style={{ color: "#000000" }}>- Rich Partnership, Resources, and Networking.</p>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} md={12} className="p-5">
                        <center>
                            <iframe width="853" height="500" src="https://www.youtube.com/embed/L0ibWJOcjDM" title="YouTube video player" className="videoAbout" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </center>
                    </Col>
                    <Col xs={12} md={12} className="">
                        <div className='title HeadMission'>
                            <h2 className='bigTitle' id='ourMission'><span className="title-Active">OUR</span> Mission</h2>
                        </div>
                    </Col>
                </Row>
                <div style={{ height: 50 }} ></div>

                <Row className="">
                    <Col xs={12} sm={6} md={6}>
                        <p className="titleMission mission1" >Mission 1</p>
                        <p className="MissionDetail">Contribute towards the achievement of sustainable waste management by promoting reducing, reusing, recycling, composting at communities’ level through 3Rs and circular economy waste innovations. </p>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <img src={M2} className="imgMission" />
                    </Col>
                </Row>


                <Row className="mt-5">
                    <Col xs={12} sm={6} md={6} lg={6} xl={6} className='missionDesktop'>
                        <center>
                            <img src={M1} className="imgMission2" />
                        </center>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6} xl={6} className='missionDesktop'>
                        <p className="titleMission smallTitle">Mission 2 </p>
                        <p className="MissionDetail">Enhance the capacity of young people and supporting them to be sustainability leader by engaging them in environmental program of ZWL  </p>
                    </Col>
                </Row>

                <Row className="mt-5 missionMobile">
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <p className="titleMission smallTitle">Mission 2</p>
                        <p className="MissionDetail">Enhance the capacity of young people and supporting them to be sustainability leader by engaging them in environmental program of ZWL  </p>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                        <center>
                            <img src={M1} className="imgMission2" />
                        </center>
                    </Col>

                </Row>

                <Row className="mt-5">
                    <Col xs={12} sm={6} md={6}>
                        <p className="titleMission">Mission 3</p>
                        <p className="MissionDetail">Support young girl or women for gender equality and provide them opportunity for skill development </p>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <img src={M3} className="imgMission3" />
                        </center>
                    </Col>
                </Row>

                <Row className="mt-5 mb-5 ">
                    <Col xs={12} sm={6} md={6} className='missionDesktop'>
                        <center>
                            <img src={M5} className="imgMission4" />
                        </center>
                    </Col>
                    <Col xs={12} sm={6} md={6} className='missionDesktop'>
                        <p className="titleMission smallTitle">Mission 4</p>
                        <p className="MissionDetail">Set up role model school and build sound knowledge and capacity base in schools on sustainable waste management in every province of Laos PDR.  </p>
                    </Col>
                </Row>

                <Row className="mt-5 mb-5 missionMobile">
                    <Col xs={12} sm={6} md={6}>
                        <p className="titleMission smallTitle">Mission 4</p>
                        <p className="MissionDetail">Set up role model school and build sound knowledge and capacity base in schools on sustainable waste management in every province of Laos PDR.  </p>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <img src={M5} className="imgMission4" />
                        </center>
                    </Col>

                </Row>


                <Row className="mt-5 mb-5">

                    <Col xs={12} sm={6} md={6}>
                        <p className="titleMission smallTitle">Mission 5</p>
                        <p className="MissionDetail">Support communities where there is vulnerable area of climate change and build the capacity of adaptation and system for readiness.  </p>
                    </Col>
                    <Col xs={12} sm={6} md={6}>
                        <center>
                            <img src={M4} className="imgMission4" />
                        </center>
                    </Col>
                </Row>
            </Container>

            <Container fluid className='missionDesktop'>
                <Row>
                    <Col xs={12} md={6} className="p-5">
                        <img src={AboutImg1} className="volunteerImg" style={{ borderRadius: "16px" }} />
                    </Col>

                    <Col xs={12} md={6} className="p-5">
                        <h1 className='bigTitle' id='ourTeam'><span className="title-Active">OUR</span> Teams</h1>
                        <p className='teamDetail'>
                            ZWL has 45 core team members and 100 supporting members. It divided into 2 team and 4 programs following:
                        </p>

                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; IT and Communication
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Management
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Climate Change
                            </label>
                        </div>


                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Waste Management
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Sustainability
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp;  Youth Development and Gender
                            </label>
                        </div>

                    </Col>
                </Row>
            </Container>

            <Container fluid className='missionMobile'>
                <Row>

                    <Col xs={12} md={6} className="p-5">
                        <h1 style={{ fontSize: "20px" }} id='team'><span className="title-Active">OUR</span> Teams</h1>
                        <p className='teamDetail'>
                            ZWL has 45 core team members and 100 supporting members. It divided into 2 team and 4 programs following:
                        </p>

                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; IT and Communication
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Management
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Climate Change
                            </label>
                        </div>


                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Waste Management
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp; Sustainability
                            </label>
                        </div>

                        <div className="form-check">
                            <label className="form-check-label listTeam" htmlFor="defaultCheck1">
                                <FaCircle style={{ color: "#00A991" }} /> &nbsp;&nbsp;&nbsp;  Youth Development and Gender
                            </label>
                        </div>

                    </Col>
                    <Col xs={12} md={6} className="p-5">
                        <img src={AboutImg1} className="volunteerImg" style={{ borderRadius: "16px" }} />
                    </Col>
                </Row>
            </Container>


            <Container>
                <Row>
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>Climate Change Team</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={Climatechange} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>

                        <p className='nornalText'>
                        Empowering youth to actively contribute to climate change mitigation through innovative thinking, learning and meaningful action.
                        </p>
                    </Col>
                </Row>
            </Container>


            <Container>
                <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>Solid Waste Management</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={Solidwastemanagement} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>
                        <p className='nornalText'>
                        The Solid Waste Management Team aims to create a cleaner and greener environment. We focus on reducing waste by encouraging people to separate, reuse, and recycle. Our goal is to raise awareness and involve the community in managing waste responsibly. We work to inspire everyone to follow simple steps for a waste-free future. Additionally, we aim to attract people with different interests and skills to engage in environmental issues.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>SDGs Team</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={SDGS} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>
                        <p className='nornalText'>
                        A Zero Waste Laos, contributing to sustainable cities and communities (SDG 11) through youth-led initiatives, taking urgent action to combat climate change (SDG 13), and building strong partnerships (SDG 17) to ensure prosperous and fulfilling lives for all (SDG 1)
                        </p>
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>Youth and Gender development Team</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={Youth} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>

                        <p className='nornalText'>
                        Youth and Gender (YG)  focuses on empowering young individuals by providing equal opportunitie, organized supportive ability for developing young leaders and fostering inclusive environments.
                        "Bringing out your potencial is the key of developing process for positive community in the future"
                        </p>
                        
                    </Col>
                </Row>
            </Container>

            <Container>
                <Row className="mt-5">
                    <Col xs={12} sm={12} md={12}>
                        <h1 style={{ color: "#000000", fontSize: "18px" }}>IT and Communication Team</h1>
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                        <img src={IT} className="volunteerImg" style={{ borderRadius: "16px", height: "auto" }} />
                    </Col>
                    <Col xs={12} sm={12} md={12} className='mt-5' style={{ textAlign: 'left', color: "#000000" }}>

                        <p className='nornalText'>
                        To use technology and communication to inspire and empower communities in Laos to create a sustainable, zero-waste future.
                        </p>
                    </Col>
                </Row>
            </Container>

            <Footer />

        </div>
    )
}
export default About