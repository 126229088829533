// imageConstants.js
//at1
import P1_1 from '../../../../../image/programe/programe2024/at1/1-1-24.jpg';
import P1_2 from '../../../../../image/programe/programe2024/at1/1-2-24.jpg';
import P1_3 from '../../../../../image/programe/programe2024/at1/1-3-24.jpg';
import P1_4 from '../../../../../image/programe/programe2024/at1/1-4-24.jpg';

//at2
import P2_1 from '../../../../../image/programe/programe2024/at2/2-1-24.jpg';
import P2_2 from '../../../../../image/programe/programe2024/at2/2-2-24.jpg';
import P2_3 from '../../../../../image/programe/programe2024/at2/2-3-24.jpg';
import P2_4 from '../../../../../image/programe/programe2024/at2/2-5-24.jpg';
import P2_5 from '../../../../../image/programe/programe2024/at2/2-6-24.jpg';


//at3
import P3_1 from '../../../../../image/programe/programe2024/at3/3-1-24.jpg';
import P3_2 from '../../../../../image/programe/programe2024/at3/3-2-24.jpg';
import P3_3 from '../../../../../image/programe/programe2024/at3/3-3-24.jpg';


//at4
import P4_1 from '../../../../../image/programe/programe2024/at4/4-1-24.jpg';
import P4_2 from '../../../../../image/programe/programe2024/at4/4-2-24.jpg';
import P4_3 from '../../../../../image/programe/programe2024/at4/4-3-24.jpg';
import P4_4 from '../../../../../image/programe/programe2024/at4/4-4-24.jpg';



const at1 = [P1_1, P1_2, P1_3, P1_4];
const at2 = [P2_1, P2_2, P2_3, P2_4, P2_5];
const at3 = [P3_1, P3_2, P3_3];
const at4 = [P4_1, P4_2, P4_3, P4_4];



export const PROGRAM_2024_IMAGES = [at1,at2,at3,at4];