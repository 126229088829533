// content.js
export const PROGRAM_2023_CONTENT = [
  {
    title: "Youth Climate Action Conference in Laos 2023",
    content: [
      "    Here are the  highlights from the Youth Climate Action Conference in Laos 2023. This dynamic event gathered over 300 participants from ministries, organizations, associations, schools, and educational institutions, all eager to engage in pressing environmental discussions.",
      "    The conference focused on addressing climate challenges specific to Laos, featuring insightful talks and presentations. We also showcased the remarkable achievements of the Zero Waste Laos Association, which has successfully promoted environmental awareness in 550 schools across 11 provinces.",
      "    To create an enjoyable and interactive atmosphere, we organized a variety of activities, including team games, video contests, and engaging booths. These activities not only fostered learning but also encouraged collaboration and a sense of community among participants.",
    ],
  },
  {
    title: "Youth for SDGs Conference in Laos 2023",
    content: [
      "    In 2023, Zero Waste Laos, with the support of Norwegian People's Aid Lao PDR, organized a remarkable Youth for SDGs Conference in Pakse District, Champasak Province.",
      "    The main objective of the conference was to raise awareness about the Sustainable Development Goals (SDGs) and to share knowledge about environmental issues with youth in Southern Laos. We welcomed 400 participants who engaged in various activities, including 18 informative booths dedicated to different SDGs. ",
      "    The enthusiasm and active participation of the youth were truly inspiring. Many expressed their desire for the event to be held in Pakse again next year, highlighting their commitment to making a positive impact in their communities",
    ],
  },
  {
    title: "Recap of Our Activity at Hakkeo School ",
    content: [
      "     In 2023, Zero Waste Laos held an engaging session at Hakkeo School in Sisaatnak District to promote knowledge about waste management.",
      "     The activity aimed to educate students and teachers on the causes and impacts of waste, as well as collaborative solutions. We also explored climate action and facilitated a Q&A session to enhance understanding of a sustainable environment.",
    ],
  },
  {
    title: "Snapshots from Sisatanak Junior High School",
    content: [
      "    On May 14, 2022, Zero Waste Laos held a seedling workshop and tree planting initiative at Sombounsathit Primary School in Vientiane, which was supported by the European Union in Laos and the NPA. The event aims to raise awareness on the importance of trees in climate change mitigation, learning about seedlings, tree care, and the participants also experimented with tree seedlings including soil compaction to plant under the phase 1 of Youth “Climate Action Project” with targeted 260 schools for tree planting across three provinces namely Vientiane Capital, Vientiane, and Bolikhamxay province.",
      "    In 2023, the Zero Waste Laos Association hosted an enlightening activity focused on waste management for secondary school students in Sisatanak District, Vientiane. This initiative was designed to empower both students and teachers with essential knowledge about environmental sustainability.",
      "    During the session, participants learned about the various causes and impacts of waste, emphasizing the importance of understanding our consumption habits. We explored collaborative solutions that can be implemented at both the school and community levels. To make the learning experience more interactive, we organized engaging Q&A activities that allowed students to ask questions and share their thoughts, deepening their understanding of environmental issues.",
    ],
  },
];
