// content.js
export const PROGRAM_2024_CONTENT = [
    {
      title: "The Youth Climate Action Conference 2024",
      content: [
        "    The Youth Climate Action Conference 2024 is an engaging platform designed to empower young individuals in their efforts to combat climate change. Held on December 13-14, 2024, the conference features a variety of educational activities aimed at raising awareness and fostering proactive engagement among participants. Through interactive sessions, attendees can explore innovative solutions to environmental challenges while connecting with like-minded peers.",
        "    One of the key highlights of the conference is the announcement of the short video competition themed 'Voice of Youth for Climate Change.' This initiative encourages participants to express their perspectives on climate issues creatively. Additionally, the Climate Challenge competition invites teams to propose actionable projects that address specific environmental concerns, promoting practical problem-solving skills among youth.",
        "    The conference also includes seminars featuring expert speakers from various sectors, including the EU, Save the Children, and UNDP, who share valuable insights on climate policy and sustainable practices. Participants can enjoy a vibrant atmosphere filled with games, performances, and networking opportunities, all contributing to a sense of community and collaboration. This event underscores the crucial role of youth in driving climate action and demonstrates that collective efforts can lead to meaningful change.",
      ],
    },
    {
      title: "The Youth for SDGs Conference 2024",
      content: [
        "    The Youth for SDGs Conference 2024 took place on December 9, 2024, at the office of the Governor of Pakse, Champasak Province. The event was inaugurated by Mr. Bounnao Fongkhamdaeng, the Deputy Governor of Pakse, and Mrs. Souksavey Keothiamchan, the founder of Zero Waste Laos Association. This conference aimed to empower youth, students, volunteers, and educators with a deeper understanding of the 18 Sustainable Development Goals (SDGs), emphasizing the importance of environmental protection and sustainable practices.",
        "    The atmosphere at the conference was lively and engaging, featuring a variety of interactive activities designed to promote learning and collaboration. Participants took part in a debate competition, engaged in Q&A sessions, and enjoyed games at 18 booths representing each of the SDGs. These activities not only fostered a sense of community but also encouraged participants to think critically about sustainability and their role in achieving these global goals.",
        "    Zero Waste Laos is proud to contribute to the dissemination of knowledge and the development of skills among young people. The success of this event is attributed to the enthusiasm and participation of all attendees. We extend our gratitude to everyone involved for making the Youth for SDGs Conference a meaningful and impactful experience.",
      ],
    },
    {
      title: "Sustainability Leadership Camp 2024",
      content: [
        "     On November 1st and 2nd, 2024, the members of Zero Waste Laos organized a successful Sustainability Leadership Camp in Vang Vieng, Vientiane Province. This event focused on enhancing leadership and teamwork skills while allowing teams to summarize their results and plan for the future.",
        "     The camp provided an excellent opportunity for team members to build stronger connections and engage in meaningful discussions to achieve the organization's goals effectively. The event was well-executed and fostered a spirit of collaboration and unity among participants.",
        "     Such a successful event would not have been possible without the preparation and support from all involved. A heartfelt thank you to our sponsors, the association president, the youth development team, and all the members who contributed their time and effort to create an atmosphere of warmth, love, and mutual support.",
      ],
    },
    {
      title: "On December 20, 2024",
      content: [
        "    On December 20, 2024, the Zero Waste Laos team, in collaboration with Nam Theun 2, organized a Green School Award ceremony at the Yommalard district club office in Khammouan province. The event featured presentations on the project, showcasing the successful tree planting initiatives in the suburbs of Nagai, Yommalath, and Mahaxay districts.",
        "    Attendees engaged in meaningful discussions with students and teachers, fostering awareness and collaboration on environmental sustainability. This event highlighted the importance of community involvement in promoting green practices and inspiring future generations.",
      ],
    },
  ];
  