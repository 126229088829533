import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// Components
import Footer from "../../../components/footer";

// Constants and Helpers
import Consts from "../../../consts";
import UploadPhoto from "../../../helpers/UploadPhoto";

// Images
import NewsCover from "../../../image/blog/NewsCover.png";
import { PROGRAM_2023_IMAGES } from "./component/2023/imageConstants23";

// Styles
import "./Index.css";

// Data
import { PROGRAM_2023_CONTENT } from "./component/2023/content23";

// Navigation Items
const NAV_ITEMS = [
  { path: Consts.PAGE_ABOUT, label: "Story" },
  { path: Consts.PAGE_ABOUT_PRO2024, label: "ZWL 2024" },
  { path: Consts.PAGE_ABOUT_PRO2023, label: "ZWL 2023", active: true },
  { path: Consts.PAGE_ABOUT_PRO2021, label: "ZWL 2021" },
  { path: Consts.PAGE_ABOUT_PRO2020, label: "ZWL 2020" },
  { path: Consts.PAGE_ABOUT_PRO2019, label: "ZWL 2019" },
];

function AboutPro2023() {
  const history = useHistory();
  const { namePhoto, buttonUploadAndShowPhoto, setWidthPhoto, setHeightPhoto } =
    UploadPhoto();
  const [showFormRegister, setShowFormRegister] = useState(false);

  const ProgramSection = ({ title, image, content, index }) => (
    <Row className="mt-4">
      <Col xs={12} sm={6} md={6}>
        <center>
          <p className={`proTitle ${title.length > 50 ? "proTitleFix" : ""}`}>
            {title}
          </p>
          <img className="proImage" src={image[0]} alt={title} />
        </center>

        <Row>
          {image?.map((pic, index) => (
            <Col xs={6} sm={6} md={6} key={index} className="p-2">
              {" "}
              <img className="proImage" src={pic} alt={`pic${index + 1}`} /> 
            </Col>
          ))}
        </Row>
      </Col>
      <Col xs={12} sm={6} md={6}>
        {content.map((paragraph, idx) => (
          <p key={idx} className="proDetail">
            {paragraph}
          </p>
        ))}
      </Col>
    </Row>
  );

  return (
    <div style={{ zIndex: 0 }}>
      <div className="hideHeight" style={{ height: 150 }}></div>

      {/* Banner Section */}
      <Container fluid className="m-0 p-0 mb-5 bgImage-about-pro">
        <Row className="m-0 p-0">
          <Col md={12} xs={12} className="m-0 p-0 banner-about-pro">
            <img className="imgBg-about-pro" src={NewsCover} alt="Banner" />
            <div className="centered-about-pro">
              <p className="proMainTitle">About us</p>
            </div>
            <div className="container-list">
              {NAV_ITEMS.map((item, index) => (
                <div
                  key={index}
                  className={`list-items ${
                    item.active ? "action-programe" : ""
                  }`}
                  onClick={() => history.push(item.path)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>

      {/* Content Sections */}
      <Container className="mt-5 mTop">
        {PROGRAM_2023_CONTENT.map((section, index) => (
          <React.Fragment key={index}>
            <ProgramSection
              title={section.title}
              image={PROGRAM_2023_IMAGES[index]}
              content={section.content}
              index={index}
            />
            {index < PROGRAM_2023_CONTENT.length - 1 && <hr />}
          </React.Fragment>
        ))}
      </Container>

      <Footer />
    </div>
  );
}

export default AboutPro2023;
